import React from "react";
import { useNavigate } from "react-router-dom";
import TopBar from "../TopBar/TopBar";
import { Box, Typography } from "@mui/material";

// Professional color schemes for each pie chart
const colorSchemes = [
    "#4A90E2", // Blue
    "#50E3C2", // Teal
    "#B5B5B5", // Gray
    "#F5A623", // Orange
    "#F8E71C", // Yellow
    "#D0021B", // Red
    "#7ED321", // Green
    "#BD10E0", // Purple
    "#FF6F61", // Coral
    "#FF9F00", // Gold
  ];

const staticChartData = [
  { subyojna_title: "दुष्‍काळामुळे बाधित शेतक-यांना मदत", data: [{ form_field_name: "Field 1", value: 10 }, { form_field_name: "Field 2", value: 20 }] },
  { subyojna_title: "नैसर्गिक आप्पत्ती मुळे मयत जनावरांचा तपशिल", data: [{ form_field_name: "Field 1", value: 15 }, { form_field_name: "Field 2", value: 25 }] },
  { subyojna_title: "Households Completed 100 days in Financial Year -2023-2024", data: [{ form_field_name: "Field 1", value: 20 }, { form_field_name: "Field 2", value: 30 }] },
  { subyojna_title: "साप्‍ताहिक मजुर उपस्थिती अहवाल", data: [{ form_field_name: "Field 1", value: 25 }, { form_field_name: "Field 2", value: 35 }] },
  { subyojna_title: "टँकर,विहीर व बोअर अधिग्रहण सदयस्थिती", data: [{ form_field_name: "Field 1", value: 30 }, { form_field_name: "Field 2", value: 40 }] },
  { subyojna_title: "संजय गांधी निराधार अनुदान योजना डी.बी.टी पोर्टल कामाचा तपशिल", data: [{ form_field_name: "Field 1", value: 35 }, { form_field_name: "Field 2", value: 45 }] },
  { subyojna_title: "श्रावणबाळ सेवा राज्‍य निवृत्‍ती वेतन योजना- डी.बी.टी पोर्टल कामाचा तपशिल", data: [{ form_field_name: "Field 1", value: 40 }, { form_field_name: "Field 2", value: 50 }] },
  { subyojna_title: "विसयो अंतर्गत  राज्‍य पुरस्‍कृत योजना जिल्‍हास्‍तरावरुन वाटप अनुदान तपशिल", data: [{ form_field_name: "Field 1", value: 45 }, { form_field_name: "Field 2", value: 55 }] },
  { subyojna_title: "राज्‍य पुरस्‍कृत योजना", data: [{ form_field_name: "Field 1", value: 50 }, { form_field_name: "Field 2", value: 60 }] },
  { subyojna_title: "अवैध उत्‍खनन  व वाहतुकीस केलेल्‍या कार्यवाहीची माहीती", data: [{ form_field_name: "Field 1", value: 55 }, { form_field_name: "Field 2", value: 65 }] },
  { subyojna_title: "अवैध गौण खनिज उत्‍खनन व वाहतूकी बाबत प्रकरणांचा तपशील", data: [{ form_field_name: "Field 1", value: 60 }, { form_field_name: "Field 2", value: 70 }] },
  { subyojna_title: "घरकुलासाठी उपलब्‍ध करुन देण्‍यात आलेल्‍या रेती बाबतची माहिती", data: [{ form_field_name: "Field 1", value: 65 }, { form_field_name: "Field 2", value: 75 }] },
  { subyojna_title: "शासकिय कामासाठी उपलब्‍ध करुन देण्‍यात आलेल्‍या वाळु बाबतची माहिती", data: [{ form_field_name: "Field 1", value: 70 }, { form_field_name: "Field 2", value: 80 }] },
];

const Cardss = () => {
    const navigate = useNavigate();
  
    const handleAllYojna = () => {
      navigate(`/tahasiltwo/10`);
    };
  
    const aggregateData = (data) => {
      const aggregation = {};
  
      data.forEach((item) => {
        const { form_field_name, value = "1" } = item; // Default value to "1" if not provided
        const numericValue = Number(value);
  
        if (!isNaN(numericValue)) {
          if (aggregation[form_field_name]) {
            aggregation[form_field_name] += numericValue;
          } else {
            aggregation[form_field_name] = numericValue;
          }
        }
      });
  
      return Object.keys(aggregation).map(key => ({
        form_field_name: key,
        totalSum: aggregation[key],
      }));
    };
  
    return (
      <Box sx={{ marginTop: { sm: "100px", xs: "12%" },}}>
        <TopBar />
        <Typography
        variant="h3"
        className="ff_yatra"
        textAlign={"center"}
        fontSize="2.5rem"
      >
        विभागीय आयुक्त कार्यालय छत्रपती संभाजीनगर
      </Typography>
        <div className="ms-16 mt-16">
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
            {staticChartData.map((data, index) => {
              const aggregatedData = aggregateData(data.data);
              const color = colorSchemes[index % colorSchemes.length];
  
              return (
                <div
                  key={index}
                  className="flex flex-col items-center shadow-lg p-4 md:p-6 border rounded-lg bg-white transition-transform duration-300 transform hover:scale-105"
                  style={{ width: "450px", borderColor: color }}
                >
                  <h3 className="text-lg font-semibold text-center mb-4" style={{ color }}>
                    {data.subyojna_title}
                  </h3>
  
                  {aggregatedData.length > 0 ? (
                    aggregatedData.slice(0, 3).map((field, idx) => (
                      <div
                        key={idx}
                        className="flex justify-between items-center w-full border-b py-2 text-gray-700"
                      >
                        <span className="text-sm">
                          {field.form_field_name}
                        </span>
                        <span className="text-sm font-semibold">
                          Total: {field.totalSum}
                        </span>
                      </div>
                    ))
                  ) : (
                    <p className="text-gray-600">No data available</p>
                  )}
                </div>
              );
            })}
          </div>
  
          <div className="flex justify-center mt-8">
            <button
              onClick={handleAllYojna}
              className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 transition-colors duration-300"
            >
              View All Yojna
            </button>
          </div>
        </div>
      </Box>
    );
  };
  

export default Cardss;
