import React, { useState, useEffect } from "react";
import { Box, CssBaseline, Typography, Button, Grid, IconButton, Dialog, DialogContent, DialogTitle, DialogActions } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import TopBar from "../TopBar/TopBar";
import MapImage from "../images/map-color-name.svg";
import Logo from "../Tahasil/logo.png";
import { Close as CloseIcon, BarChart as BarChartIcon, ViewComfy as ViewComfyIcon, Apps as AppsIcon } from '@mui/icons-material';

// Data for each Jilha and its Talukas
const jilhaData = [
    {
        id: 2,
        name: "छत्रपती संभाजीनगर",
        talukas: [
            "फुलंब्री",
            "कन्नड",
            "पैठण",
            "सिल्लोड",
            "गंगापूर",
            "वैजापूर",
            "जिल्हा अहवाल" // Special button
        ]
    },
    {
        id: 3,
        name: "बीड",
        talukas: [
            "अंबाजोगाई",
            "गेराई",
            "कायम",
            "मजलगाांव",
            "परळी",
            "वडवणी",
            "धारूर",
            "अस्टी",
            "भांगेगांव",
            "जिल्हा अहवाल" // Special button
        ]
    },
    {
        id: 4,
        name: "जालना",
        talukas: [
            "जालना",
            "अंबड",
            "बदनापूर",
            "घनसवंगी",
            "मंथा",
            "पारतुर",
            "सिल्लोड",
            "मुंडगाव",
            "जाफ्राबाद",
            "जिल्हा अहवाल" // Special button
        ]
    },
    {
        id: 5,
        name: "परभणी",
        talukas: [
            "परभणी",
            "जिंटूर",
            "पूरना",
            "सोनपेठ",
            "मनवट",
            "डोंगाव",
            "पलम",
            "पुर्ना",
            "खंडाला",
            "जिल्हा अहवाल" // Special button
        ]
    },
    {
        id: 6,
        name: "लातूर",
        talukas: [
            "लातूर",
            "अमजदपूर",
            "कुरनूर",
            "गणपती",
            "जुन्नर",
            "गोकाक",
            "पाळेगाव",
            "उटगाव",
            "आधार",
            "जिल्हा अहवाल" // Special button
        ]
    },
    {
        id: 7,
        name: "हिंगोली",
        talukas: [
            "हिंगोली",
            "सावली",
            "कुलकर्णी",
            "मांगळ",
            "झारखंड",
            "शाहपुर",
            "कुणबारा",
            "शिवपुर",
            "गंगाखेड",
            "जिल्हा अहवाल" // Special button
        ]
    },
    {
        id: 9,
        name: "धाराशिव",
        talukas: [
            "धाराशिव",
            "सांगली",
            "तारापुर",
            "वडगाव",
            "पाटस",
            "साळगाव",
            "मोरे",
            "कुंभोज",
            "नांदगाव",
            "जिल्हा अहवाल" // Special button
        ]
    }
];


const Jilha = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalTalukaId, setModalTalukaId] = useState(null);
    const [selectedJilha, setSelectedJilha] = useState(null);
    const navigate = useNavigate();
    const { id } = useParams(); // Get id from URL parameters

    useEffect(() => {
        // Find the Jilha based on the id from URL parameters
        const jilha = jilhaData.find(jilha => jilha.id === parseInt(id));
        if (jilha) {
            setSelectedJilha(jilha);
        } else {
            // Handle the case where no matching Jilha is found (e.g., redirect or show an error)
            navigate("/not-found"); // Example redirect to a 404 page
        }
    }, [id, navigate]);
    const {ids} = useParams()
    const handleNavigate = (taluka) => {
        if (taluka === "जिल्हा अहवाल") {
            setModalTalukaId(taluka);
            setIsModalOpen(true);
        } else {
            navigate(`/StaticTabsComponent/${ids}`);
        }
    };

    const handleModalActionGraph = () => {
        setIsModalOpen(false);
        navigate(`/piecharts`);
    };

    const handleModalActionCard = () => {
        setIsModalOpen(false);
        navigate(`/cardss`);
    };

    const handleModalAll = (action) => {
        setIsModalOpen(false);
        navigate(`/StaticTabsComponentjillha/10`);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    if (!selectedJilha) {
        return null; // or a loading spinner, or an error message
    }

    return (
        <Box
            sx={{
                flexGrow: 1,
                p: 3,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                marginTop: { xs: "20px", sm: "5%" },
            }}
        >
            <CssBaseline />
            <Box>
                <TopBar />

                <Grid container spacing={6} justifyContent={"center"} alignItems={"center"}>
                    {/* Left Grid */}
                    <Grid item xs={12} md={5}>
                        <Box className="map-image" p={2} boxShadow={"rgba(0, 0, 0, 0.1) 0px 10px 30px 4px;"}>
                            <img src={MapImage} alt="Map" />
                            <Box>
                                <img src={Logo} className="logo" alt="Logo" />
                            </Box>
                        </Box>
                    </Grid>

                    {/* Right Grid */}
                    <Grid item xs={12} md={6}>
                        <Box p={2} boxShadow={"rgba(0, 0, 0, 0.1) 0px 10px 30px 4px;"}>
                            <Typography variant="h3" className="ff_yatra" textAlign={"center"} fontSize="2.5rem">
                                जिल्हाधिकारी कार्यालय {selectedJilha.name}
                            </Typography>

                            <Box component="main" sx={{ flexGrow: 1 }}>
                                <Box
                                    pt={5}
                                    sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "center",
                                    }}
                                >
                                    {/* Special Button at the top */}
                                    <Box mb={3} sx={{ display: 'flex', justifyContent: 'center' }}>
                                        <Button
                                            onClick={() => handleNavigate("जिल्हा अहवाल")}
                                            className="ff_yatra"
                                            variant="contained"
                                            sx={{
                                                height: 60,
                                                fontSize: 25,
                                                width: 220,
                                                background: 'linear-gradient(to right, #FFCC70, #C850C0, #4158D0)',
                                                transition: 'background 0.3s, transform 0.3s',
                                                '&:hover': {
                                                    background: 'linear-gradient(to right, rgba(2,0,36,1), rgba(0,212,255,1))',
                                                    transform: 'scale(1.05)',
                                                },
                                            }}
                                        >
                                            जिल्हा अहवाल
                                        </Button>
                                    </Box>

                                    {/* Taluka Buttons in a row */}
                                  {/* Taluka Buttons in a row */}
<Grid container spacing={2} justifyContent="center">
    {selectedJilha.talukas
        .filter(taluka => taluka !== "जिल्हा अहवाल") // Exclude the special button
        .map((taluka, index) => {
            // Define the talukas that should be disabled
            const disabledTalukas = [
                "जालना", "बदनापूर", "घनसवंगी", 
                "मंथा", "पारतुर", "सिल्लोड", 
                "मुंडगाव", "जाफ्राबाद"
            ];
            
            return (
                <Grid item xs={12} sm={4} key={index}>
                    <Button
                        onClick={() => handleNavigate(taluka)}
                        className="ff_yatra"
                        variant="contained"
                        disabled={disabledTalukas.includes(taluka)} // Disable condition
                        sx={{
                            height: 50,
                            fontSize: 20,
                            width: '100%', // Adjust width to fit container
                            background: disabledTalukas.includes(taluka)
                                ? 'gray' // Set a different background color for disabled buttons
                                : 'linear-gradient(to right, rgba(2,0,36,1), rgba(0,212,255,1))',
                            transition: 'background 0.3s, transform 0.3s',
                            '&:hover': {
                                background: disabledTalukas.includes(taluka)
                                    ? 'gray' // Maintain the background color for disabled buttons on hover
                                    : 'linear-gradient(to right, rgba(2,0,36,1), rgba(0,212,255,1))',
                                transform: disabledTalukas.includes(taluka) ? 'none' : 'scale(1.05)',
                            },
                        }}
                    >
                        {taluka}
                    </Button>
                </Grid>
            );
        })}
</Grid>


                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Box>

            {/* Modal Dialog */}
            <Dialog
                open={isModalOpen}
                onClose={handleCloseModal}
                PaperProps={{
                    style: {
                        background: 'linear-gradient(to right, #e3f2fd, #ffffff)',
                        borderRadius: '20px',
                        boxShadow: '0 12px 24px rgba(0,0,0,0.3)',
                    }
                }}
            >
                <DialogTitle>
                    {/* Modal Title */}

                    <IconButton
                        onClick={handleCloseModal}
                        sx={{ position: 'absolute', top: 8, right: 8 }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <Typography variant="body1" textAlign={"center"} className="ff_yatra">
                        Choose an action
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => handleModalActionGraph()}
                        variant="contained"
                        sx={{ background: '#4caf50', '&:hover': { background: '#45a049' } }}
                    >
                        View Graph
                    </Button>
                    <Button
                        onClick={() => handleModalActionCard()}
                        variant="contained"
                        sx={{ background: '#4caf50', '&:hover': { background: '#45a049' } }}
                    >
                        View Card
                    </Button>
                    <Button
                        onClick={() => handleModalAll()}
                        variant="contained"
                        sx={{ background: '#4caf50', '&:hover': { background: '#45a049' } }}
                    >
                        View All
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default Jilha;
