import React, { useState } from "react";
import { Box, CssBaseline, Typography, Button, Grid, IconButton, Dialog, DialogContent, DialogTitle, DialogActions } from "@mui/material";
import { useNavigate } from "react-router-dom";
import TopBar from "../TopBar/TopBar";
import MapImage from "../images/marathawada.svg";
import Logo from "../Tahasil/logo.png";
// import Logo from "../../src/images/marathawada.svg";
import { Close as CloseIcon, BarChart as BarChartIcon, ViewComfy as ViewComfyIcon, Apps as AppsIcon } from '@mui/icons-material'; 

const Vibhag = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalTalukaId, setModalTalukaId] = useState(null);
  const navigate = useNavigate();

  const handleNavigate = (talukaId) => {
    if (talukaId === "विभाग अहवाल") {
      setModalTalukaId(talukaId);
      setIsModalOpen(true);
    } else {
      navigate(`/jilha/${talukaId}`);
    }
  };

  const handleModalActionGraph = () => {
    setIsModalOpen(false);
    navigate(`/piecharts`);
  };
  const handleModalActionCard = () => {
    setIsModalOpen(false);
    navigate(`/cardss`);
  };
  const handleModalAll = () => {
    setIsModalOpen(false);
    navigate(`/StaticTabsComponentvibhag/10`);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  // Talukas with unique IDs
  const talukas = [
    { id: 1, name: "विभाग अहवाल" },
    { id: 2, name: "छत्रपती संभाजीनगर" },
    { id: 3, name: "बीड" },
    { id: 4, name: "जालना" },
    { id: 5, name: "परभणी" },
    { id: 6, name: "लातूर" },
    { id: 7, name: "हिंगोली" },
    { id: 8, name: "नांदेड" },
    { id: 9, name: "धाराशिव" }
  ];

  return (
    <Box
      sx={{
        flexGrow: 1,
        p: 3,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        marginTop: { xs: "20px", sm: "5%" },
      }}
    >
      <CssBaseline />
      <Box>
        <TopBar />

        <Grid container spacing={6} justifyContent={"center"} alignItems={"center"}>
          {/* Left Grid */}
          <Grid item xs={12} md={5}>
            <Box className="map-image" p={2} boxShadow={"rgba(0, 0, 0, 0.1) 0px 10px 30px 4px;"}>
              <img src={MapImage} alt="Map" />
              <Box>
                <img src={Logo} className="logo" alt="Logo" />
              </Box>
            </Box>
          </Grid>

          {/* Right Grid */}
          <Grid item xs={12} md={6}>
            <Box p={2} boxShadow={"rgba(0, 0, 0, 0.1) 0px 10px 30px 4px;"}>
              <Typography variant="h3" className="ff_yatra" textAlign={"center"} fontSize="2.5rem">
                विभागीय आयुक्त कार्यालय छत्रपती संभाजीनगर
              </Typography>
              <Box component="main" sx={{ flexGrow: 1 }}>
                <Box
                  pt={5}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  {/* "विभाग अहवाल" Button at the Top and Center */}
                  <Grid container justifyContent="center" sx={{ mb: 3 }}>
                    <Grid item>
                      <Button
                        onClick={() => handleNavigate("विभाग अहवाल")}
                        className="ff_yatra"
                        variant="contained"
                        sx={{
                          height: 60,
                          fontSize: 25,
                          width: 220,
                          background: "linear-gradient(43deg, #FFCC70 0%, #C850C0 46%, #4158D0 100%)",
                          transition: "background 0.3s, transform 0.3s",
                          "&:hover": {
                            background: "linear-gradient(43deg, #FFCC70 0%, #C850C0 46%, #4158D0 100%)",
                            transform: "scale(1.05)",
                          },
                          m: 1,
                        }}
                      >
                        विभाग अहवाल
                      </Button>
                    </Grid>
                  </Grid>

                  {/* Other Taluka Buttons */}
                  <Grid
                    container
                    spacing={2}
                    justifyContent="center"
                    sx={{ mb: 3 }}
                  >
                    {talukas
                      .filter(taluka => taluka.name !== "विभाग अहवाल")
                      .map((taluka) => (
                        <Grid item key={taluka.id}>
                          <Button
                            onClick={() => handleNavigate(taluka.id)}
                            className="ff_yatra"
                            variant="contained"
                            sx={{
                              height: 50,
                              fontSize: 18,
                              width: 180,
                              background: "linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(0,147,255,1) 0%, rgba(0,212,255,1) 100%)",
                              transition: "background 0.3s, transform 0.3s",
                              "&:hover": {
                                background: "linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(0,212,255,1) 0%, rgba(0,147,255,1) 100%)",
                                transform: "scale(1.05)",
                              },
                              m: 1, // Margin for spacing between buttons
                            }}
                            disabled={taluka.id !== 1 && taluka.id !== 4}
                          >
                            {taluka.name}
                          </Button>
                        </Grid>
                      ))}
                  </Grid>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>

      {/* Custom Modal */}
      {isModalOpen && (
        <Dialog
          open={isModalOpen}
          onClose={handleCloseModal}
          fullWidth
          maxWidth="xs"
          PaperProps={{
            sx: {
              borderRadius: "20px",
              p: 3,
              boxShadow: "0 12px 24px rgba(0,0,0,0.3)",
              background: "linear-gradient(145deg, #e3f2fd, #ffffff)",
            },
          }}
        >
          <DialogTitle>
            <Typography variant="h6" fontWeight="bold" color="primary" textAlign="center">
              Select an Action
            </Typography>
            <IconButton
              aria-label="close"
              onClick={handleCloseModal}
              sx={{ position: "absolute", right: 8, top: 8, color: "#ff4081" }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>

          <DialogContent dividers>
            <Box display="flex" justifyContent="center" mb={2}>
            </Box>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 2,
                    alignItems: "center",
                  }}
                >
                  <Button
                    onClick={() => handleModalActionGraph()}
                    sx={{
                      width: "100%",
                      py: 1.5,
                      textTransform: "none",
                      background: "linear-gradient(135deg, #64b5f6, #1e88e5)",
                      borderRadius: "15px",
                      transition: "transform 0.2s ease-in-out",
                      "&:hover": {
                        background: "linear-gradient(135deg, #42a5f5, #2196f3)",
                        transform: "scale(1.05)",
                      },
                    }}
                    variant="contained"
                    startIcon={<BarChartIcon />}
                  >
                    View in Graph
                  </Button>

                  <Button
                    onClick={() => handleModalActionCard()}
                    sx={{
                      width: "100%",
                      py: 1.5,
                      textTransform: "none",
                      background: "linear-gradient(135deg, #81c784, #43a047)",
                      borderRadius: "15px",
                      transition: "transform 0.2s ease-in-out",
                      "&:hover": {
                        background: "linear-gradient(135deg, #66bb6a, #388e3c)",
                        transform: "scale(1.05)",
                      },
                    }}
                    variant="contained"
                    startIcon={<ViewComfyIcon />}
                  >
                    View in Card
                  </Button>

                  <Button
                    onClick={() => handleModalAll()}
                    sx={{
                      width: "100%",
                      py: 1.5,
                      textTransform: "none",
                      background: "linear-gradient(135deg, #ffca28, #f57f17)",
                      borderRadius: "15px",
                      transition: "transform 0.2s ease-in-out",
                      "&:hover": {
                        background: "linear-gradient(135deg, #ffb300, #f57c00)",
                        transform: "scale(1.05)",
                      },
                    }}
                    variant="contained"
                    startIcon={<AppsIcon />}
                  >
                    View All
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseModal} sx={{ textTransform: "none" }}>
              Close
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Box>
  );
};

export default Vibhag;
