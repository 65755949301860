import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Box,
  Button,
} from "@mui/material";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import * as XLSX from "xlsx";
import TopBar from "../TopBar/TopBar";
import { useParams } from "react-router-dom";

const TablessVibhag = () => {
  const { title } = useParams();
  let staticData = [
    {
      taluka: "जालना",
      field1: "100",
      field2: "754",
      field3: "500",
      field4: "450",
      field5: "400",
      field6: "250",
      field7: "100",
    },
    {
      taluka: "औरंगाबाद",
      field1: "150",
      field2: "600",
      field3: "350",
      field4: "300",
      field5: "350",
      field6: "200",
      field7: "150",
    },
    {
      taluka: "बीड",
      field1: "120",
      field2: "650",
      field3: "450",
      field4: "350",
      field5: "420",
      field6: "300",
      field7: "100",
    },
    {
      taluka: "परभणी",
      field1: "110",
      field2: "500",
      field3: "400",
      field4: "300",
      field5: "410",
      field6: "270",
      field7: "120",
    },
    {
      taluka: "नांदेड",
      field1: "130",
      field2: "550",
      field3: "300",
      field4: "330",
      field5: "450",
      field6: "290",
      field7: "140",
    },
    {
      taluka: "लातूर",
      field1: "140",
      field2: "700",
      field3: "480",
      field4: "390",
      field5: "460",
      field6: "260",
      field7: "110",
    },
    {
      taluka: "उस्मानाबाद",
      field1: "160",
      field2: "720",
      field3: "410",
      field4: "400",
      field5: "440",
      field6: "280",
      field7: "130",
    },
    {
      taluka: "हिंगोली",
      field1: "170",
      field2: "750",
      field3: "490",
      field4: "420",
      field5: "470",
      field6: "300",
      field7: "150",
    },
  ];
  

  const generatePDF = () => {
    const input = document.getElementById("pdf-table");
    if (!input) return;

    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF();
      pdf.addImage(imgData, "PNG", 10, 10);
      pdf.save("YojanaTable.pdf");
    });
  };

  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(staticData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, "YojanaTable.xlsx");
  };

  return (
    <Box sx={{ mt: 10, width: "90vw", marginLeft: "6%" }}>
      <TopBar />
      <Box id="pdf-table" sx={{ mb: 4 }}>
        <Typography
          variant="h3"
          className="ff_yatra"
          textAlign={"center"}
          fontSize="2.5rem"
        >
          विभागीय आयुक्त कार्यालय छत्रपती संभाजीनगर
        </Typography>
        <Typography
          variant="h6"
          sx={{
            mb: 2,
            fontWeight: "bold",
            textAlign: "center",
            paddingBlock: "20px",
          }}
          className="ff_yatra"
        >
          {title}
        </Typography>
        <Box sx={{ display: "flex", justifyContent: "center", mt: 3, gap: 2 }}>
          <Button
            variant="contained"
            color="secondary"
            onClick={exportToExcel}
            sx={{
              padding: "10px 20px",
              fontSize: "16px",
              position: "absolute",
              right: "5%",
              top: "20%",
            }}
          >
            Download Excel
          </Button>
        </Box>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow sx={{ backgroundColor: "#aeaeae" }}>
                <TableCell
                  align="center"
                  sx={{ fontWeight: "bold", color: "#000", border: 1 }}
                >
                  #
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ fontWeight: "bold", color: "#000", border: 1 }}
                >
                  तालुका
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ fontWeight: "bold", color: "#000", border: 1 }}
                >
                  मयत झालेल्‍या जनावरांची संख्‍या
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ fontWeight: "bold", color: "#000", border: 1 }}
                >
                  PM झालेल्‍या जनावरांची संख्‍या
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ fontWeight: "bold", color: "#000", border: 1 }}
                >
                  बील तयार झालेली संख्‍या
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ fontWeight: "bold", color: "#000", border: 1 }}
                >
                  कोषागारामध्‍ये दाखल बिलाची संख्‍या
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ fontWeight: "bold", color: "#000", border: 1 }}
                >
                  रजिस्‍ट्रेशन झालेली संख्‍या
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ fontWeight: "bold", color: "#000", border: 1 }}
                >
                  प्रलंबित
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {staticData.map((row, index) => (
                <TableRow key={index}>
                  <TableCell
                    align="center"
                    sx={{ fontWeight: "light", color: "#000", border: 1 }}
                  >
                    {index + 1} {/* Row numbering */}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ fontWeight: "light", color: "#000", border: 1 }}
                  >
                    {row.taluka}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ fontWeight: "light", color: "#000", border: 1 }}
                  >
                    {row.field1}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ fontWeight: "light", color: "#000", border: 1 }}
                  >
                    {row.field2}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ fontWeight: "light", color: "#000", border: 1 }}
                  >
                    {row.field3}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ fontWeight: "light", color: "#000", border: 1 }}
                  >
                    {row.field4}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ fontWeight: "light", color: "#000", border: 1 }}
                  >
                    {row.field5}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ fontWeight: "light", color: "#000", border: 1 }}
                  >
                    {row.field6}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
};

export default TablessVibhag;
