import React from "react";
import { useNavigate } from "react-router-dom";
import TopBar from "../TopBar/TopBar";
import { Chart } from "react-google-charts";
import { Typography } from "@mui/material";

// Color schemes for each pie chart
const colorSchemes = [
  ["#FF5733", "#C70039", "#900C3F"],
  ["#DAF7A6", "#FFC300", "#FF5733"],
  ["#581845", "#900C3F", "#C70039"],
  ["#FF6F61", "#6B5B95", "#FF5733"],
  ["#88B04B", "#F7CAC9", "#FFC300"],
  ["#FFCCCB", "#D5AAFF", "#C70039"],
  ["#A9DFBF", "#F5B041", "#900C3F"],
  ["#E67E22", "#1F618D", "#FF5733"],
  ["#EC7063", "#48C9B0", "#900C3F"],
  ["#F39C12", "#E74C3C", "#C70039"],
  ["#DAF7A6", "#FFC300", "#FF5733"],
  ["#581845", "#900C3F", "#C70039"],
];

const staticChartData = [
  { subyojna_title: "दुष्‍काळामुळे बाधित शेतक-यांना मदत", data: [{ form_field_name: "Field 1", value: 10 }, { form_field_name: "Field 2", value: 20 }] },
  { subyojna_title: "नैसर्गिक आप्पत्ती मुळे मयत जनावरांचा तपशिल", data: [{ form_field_name: "Field 1", value: 15 }, { form_field_name: "Field 2", value: 25 }] },
  { subyojna_title: "Households Completed 100 days in Financial Year -2023-2024", data: [{ form_field_name: "Field 1", value: 20 }, { form_field_name: "Field 2", value: 30 }] },
  { subyojna_title: "साप्‍ताहिक मजुर उपस्थिती अहवाल", data: [{ form_field_name: "Field 1", value: 25 }, { form_field_name: "Field 2", value: 35 }] },
  { subyojna_title: "टँकर,विहीर व बोअर अधिग्रहण सदयस्थिती", data: [{ form_field_name: "Field 1", value: 30 }, { form_field_name: "Field 2", value: 40 }] },
  { subyojna_title: "संजय गांधी निराधार अनुदान योजना डी.बी.टी पोर्टल कामाचा तपशिल", data: [{ form_field_name: "Field 1", value: 35 }, { form_field_name: "Field 2", value: 45 }] },
  { subyojna_title: "श्रावणबाळ सेवा राज्‍य निवृत्‍ती वेतन योजना- डी.बी.टी पोर्टल कामाचा तपशिल", data: [{ form_field_name: "Field 1", value: 40 }, { form_field_name: "Field 2", value: 50 }] },
  { subyojna_title: "विसयो अंतर्गत  राज्‍य पुरस्‍कृत योजना जिल्‍हास्‍तरावरुन वाटप अनुदान तपशिल", data: [{ form_field_name: "Field 1", value: 45 }, { form_field_name: "Field 2", value: 55 }] },
  { subyojna_title: "राज्‍य पुरस्‍कृत योजना", data: [{ form_field_name: "Field 1", value: 50 }, { form_field_name: "Field 2", value: 60 }] },
  { subyojna_title: "अवैध उत्‍खनन  व वाहतुकीस केलेल्‍या कार्यवाहीची माहीती", data: [{ form_field_name: "Field 1", value: 55 }, { form_field_name: "Field 2", value: 65 }] },
  { subyojna_title: "अवैध गौण खनिज उत्‍खनन व वाहतूकी बाबत प्रकरणांचा तपशील", data: [{ form_field_name: "Field 1", value: 60 }, { form_field_name: "Field 2", value: 70 }] },
  { subyojna_title: "घरकुलासाठी उपलब्‍ध करुन देण्‍यात आलेल्‍या रेती बाबतची माहिती", data: [{ form_field_name: "Field 1", value: 65 }, { form_field_name: "Field 2", value: 75 }] },
  { subyojna_title: "शासकिय कामासाठी उपलब्‍ध करुन देण्‍यात आलेल्‍या वाळु बाबतची माहिती", data: [{ form_field_name: "Field 1", value: 70 }, { form_field_name: "Field 2", value: 80 }] },
];

const Piecharts = () => {
  const navigate = useNavigate();

  const handleAllYojna = () => {
    navigate(`/tahasiltwo/10`);
  };

  // Helper function to calculate aggregated sums by form_field_name
  const aggregateData = (data) => {
    const aggregation = {};

    data.forEach((item) => {
      const { form_field_name, value = "1" } = item; // Default value to "1" if not provided
      const numericValue = Number(value);

      if (!isNaN(numericValue)) {
        if (aggregation[form_field_name]) {
          aggregation[form_field_name] += numericValue;
        } else {
          aggregation[form_field_name] = numericValue;
        }
      }
    });

    return Object.keys(aggregation).map(key => ({
      form_field_name: key,
      totalSum: aggregation[key],
    }));
  };

  // Function to convert aggregated data to pie chart format
  const formatPieData = (data) => {
    const aggregatedData = aggregateData(data);
    const pieData = [["Form Field Name", "Total"]];

    // Take only the first three items
    aggregatedData.slice(0, 3).forEach((field) => {
      pieData.push([field.form_field_name, field.totalSum]);
    });

    return pieData;
  };

  return (
   <>
     <Typography
        variant="h3"
        className="ff_yatra"
        textAlign={"center"}
        fontSize="2.5rem"
        sx={{ marginTop: { sm: "100px", xs: "12%" },}}
      >
        विभागीय आयुक्त कार्यालय छत्रपती संभाजीनगर
      </Typography>
    <div className="ms-16 mt-16">
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
        {staticChartData.map((data, index) => {
          // Format pie chart data
          const pieData = formatPieData(data.data);
          const colors = colorSchemes[index % colorSchemes.length]; // Use color schemes in a cyclic manner

          const slices = pieData.slice(1).map((_, idx) => ({
            color: colors[idx] || "#000000", // Default color if not enough colors
          }));

          return (
            <div
              key={index}
              className="flex flex-col items-center shadow-lg p-4 md:p-6 border rounded-lg bg-white"
              style={{ width: "450px" }}
            >
              <h3 className="text-lg font-semibold text-center mb-4">
                {data.subyojna_title}
              </h3>

              {/* Pie Chart */}
              <Chart
                chartType="PieChart"
                data={pieData}
                options={{
                  pieHole: 1, // Optional: Donut chart
                  is3D: true, // Optional: 3D effect
                  slices: slices.reduce((acc, _, idx) => {
                    acc[idx] = { color: colors[idx] || "#000000" }; // Apply color
                    return acc;
                  }, {}),
                  legend: {
                    position: "bottom",
                    alignment: "center",
                    textStyle: {
                      fontSize: 14,
                    },
                  },
                  chartArea: { width: "90%", height: "70%" },
                  pieSliceTextStyle: {
                    color: "white",
                    fontSize: 16,
                  },
                  backgroundColor: "#f4f4f4",
                }}
                width="100%"
                height="300px"
              />

              {/* Display form field names and the total sum */}
              {aggregateData(data.data).length > 0 ? (
                aggregateData(data.data).slice(0, 3).map((field, idx) => (
                  <div
                    key={idx}
                    className="flex justify-between items-center w-full border-b py-2"
                  >
                    <span className="text-sm text-gray-600">
                      {field.form_field_name}
                    </span>
                    <span className="text-sm font-semibold text-gray-800">
                      Total: {field.totalSum}
                    </span>
                  </div>
                ))
              ) : (
                <p className="text-gray-600">No data available</p>
              )}
            </div>
          );
        })}
      </div>

      {/* View All Charts Button */}
      <div className="flex justify-center mt-8">
        <button
          onClick={handleAllYojna}
          className="bg-orange-500 text-white py-2 px-6 rounded-lg shadow-lg hover:bg-orange-600 transition duration-300"
        >
          View All Yojna
        </button>
      </div>

      <TopBar />
    </div>
   </>
  );
};

export default Piecharts;
