import React, { useState, useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import Home from './home/Home';
import Tahasil from './Tahasil/Tahasil';
import YojanaTable from './YojanaTable/YojanaTable';
import Preloader from './Preloader/Preloader';
import TahasilTwo from './TahasilTwo/TahasilTwo';
import YojanaTableOne from './YojanaTable/YojanaTableOne';
import YojanaTableTwo from './YojanaTable/YojanaTableTwo';
import YojanaTableThree from './YojanaTable/YojanaTableThree';
import LoginForm from './Login/LoginForm';
import YojanaTableFour from './YojanaTable/YojanaTableFour';
import Cards from './Table/CardData';
// SGY Tables
import TableOne from './YojanaTable/SGY/TableOne';
import DataTable from './Table/DataTable';
import PieChartComponent from './Charts/Charts';
import SgyOne from './Table/SgyOne';

import PrivateRoute from './components/PrivateRoute';
import TahsilTable from './YojanaTable/TahsilTable';
import { XDChart } from './Charts/3DChart';
import Vibhag from './vibhag/vibhag';
import Jilha from './vibhag/Jilha';
import Charts from './Table/SgyOne';
import Piecharts from './vibhag/Charts';
import Cardss from './vibhag/Cards';
import StaticTabsComponent from './vibhag/StaticTabsComponent';
import Table from './vibhag/Table';
import Tabless from './vibhag/Table';
import StaticTabsComponentVibhag from './vibhag/StaticTabsComponentVibhag';
import TablessVibhag from './vibhag/TablesVibhag';
import StaticTabsComponentVibhagJillha from './vibhag/StaticTabsComponentJillha';
import StaticTabsComponentJillha from './vibhag/StaticTabsComponentJillha';
import TablessJillha from './vibhag/TableJillha';

function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  return (
    <>
      {loading ? (
        <Preloader />
      ) : (
        <Routes>
          {/* Public Routes */}
          <Route path="/" element={<LoginForm />} />
          
          {/* Protected Routes */}
          <Route path="/home" element={<PrivateRoute element={Home} />} />
          <Route path="/tahasil" element={<PrivateRoute element={Tahasil} />} />
          <Route path="/vibhag" element={<PrivateRoute element={Vibhag} />} />
          <Route path="/jilha/:id" element={<PrivateRoute element={Jilha} />} />
          <Route path="/yojana" element={<PrivateRoute element={YojanaTable} />} />
          <Route path="/yojanatwo" element={<PrivateRoute element={YojanaTableTwo} />} />
          <Route path="/tahasiltwo/:id" element={<PrivateRoute element={TahasilTwo} />} />
          {/* <Route path="sgyOne/:id" element={<PrivateRoute element={SgyOne} />} /> */}
          <Route path="piecharts" element={<PrivateRoute element={Piecharts} />} />
          <Route path="cardss" element={<PrivateRoute element={Cardss} />} />
          <Route path="cards/:id" element={<PrivateRoute element={Cards} />} />
          <Route path="/tahasiltwo/:id/tableone/:subyojnaId" element={<PrivateRoute element={YojanaTableOne} />} />
          <Route path="/tahasiltwo/:id/tahsilOne/:subyojnaId" element={<PrivateRoute element={TahsilTable} />} />
          <Route path="/tabletwo" element={<PrivateRoute element={YojanaTableTwo} />} />
          <Route path="/tablethree" element={<PrivateRoute element={YojanaTableThree} />} />
          <Route path="/tablefour" element={<PrivateRoute element={YojanaTableFour} />} />
          <Route path="/tableonesgy" element={<PrivateRoute element={TableOne} />} />
          <Route path="/datatable" element={<PrivateRoute element={DataTable} />} />
          <Route path="/chart" element={<PrivateRoute element={PieChartComponent} />} />
          <Route path="/3dchart" element={<PrivateRoute element={XDChart} />}/>
          <Route path="/StaticTabsComponent/:id" element={<PrivateRoute element={StaticTabsComponent} />}/>
          <Route path="/StaticTabsComponentvibhag/:id" element={<PrivateRoute element={StaticTabsComponentVibhag} />}/>
          <Route path="/StaticTabsComponentjillha/:id" element={<PrivateRoute element={StaticTabsComponentJillha} />}/>
          <Route path="/table/:title" element={<PrivateRoute element={Tabless} />}/>
          <Route path="/tablevibhag/:title" element={<PrivateRoute element={TablessVibhag} />}/>
          <Route path="/tablejillha/:title" element={<PrivateRoute element={TablessJillha} />}/>
        </Routes>
      )}
    </>
  );
}

export default App;
