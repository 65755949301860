import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import { Tabs, Tab, Typography, Box } from "@mui/material";
import { FaStar } from "react-icons/fa";
import ArrowRightAlt from "@mui/icons-material/ArrowRightAlt";
import TopBar from "../TopBar/TopBar";
import { useNavigate } from "react-router-dom";

// Static data
const staticYojanas = [
  {
    yojna_id: 1,
    name: "महसुल विभाग",
    subyojnas: [
      {
        subyojna_id: 101,
        name: "दुष्काळामुळे बाधित शेतक-यांना मदत",
        descriptionOne:
          "सुधारीत आकडेवारीनुसार पोर्टलवर अपलोड करणे शिल्‍लक लाभार्थी संख्‍या व रक्‍कम निधी: 200",
        descriptionTwo:
          "मयत/स्‍थलांतर/दुबार व इतर कारणामुळे होणार नसलेली Ekyc लाभार्थी संख्‍या: 754",
      },
      {
        subyojna_id: 102,
        name: "नैसर्गिक आप्पत्ती मुळे मयत जनावरांचा तपशिल",
        descriptionOne: "मयत झालेल्‍या जनावरांची संख्‍या: 100",
        descriptionTwo: "PM झालेल्‍या जनावरांची संख्‍या: 50",
      },
      {
        subyojna_id: 103,
        name: "टँकर, विहीर व बोअर अधिग्रहण सदयस्थिती",
        descriptionOne:
          "टॅंकर सुरु असलेल्‍या गावांची/ वाडयांची लोकसंख्‍या: 1000",
        descriptionTwo: "टॅंकर सुरु असलेल्‍या गावाची संख्‍या: 60",
      },
    ],
  },
  {
    yojna_id: 2,
    name: "रोजगार हमी योजना",
    subyojnas: [
      {
        subyojna_id: 201,
        name: "साप्‍ताहिक मजुर उपस्थिती अहवाल",
        descriptionOne: "पंचायतस्‍तर (कामे): 2000",
        descriptionTwo: "पंचायतस्‍तर (मजुर उपस्थिती): 400",
      },
      {
        subyojna_id: 202,
        name: "Households Completed 100 days",
        descriptionOne: "Block: 100",
        descriptionTwo: "Household Employee: 300",
      },
    ],
  },
  {
    yojna_id: 3,
    name: "संजय गांधी योजना",
    subyojnas: [
      {
        subyojna_id: 301,
        name: "संजय गांधी निराधार अनुदान योजना",
        descriptionOne: "SGY TOTAL BENEFICIARY: 4000",
        descriptionTwo: "पोर्टलवर अपलोड लाभार्थी संख्‍या: 2000",
      },
      {
        subyojna_id: 302,
        name: "श्रावणबाळ सेवा राज्‍य निवृत्‍ती वेतन योजना",
        descriptionOne: "SGY TOTAL BENEFICIARY: 5000",
        descriptionTwo: "पोर्टलवर अपलोड लाभार्थी संख्‍या: 4000",
      },
      {
        subyojna_id: 303,
        name: "विसयो अंतर्गत राज्‍य पुरस्‍कृत योजना जिल्‍हास्‍तरावरुन वाटप अनुदान तपशिल",
        descriptionOne: "लाभार्थी संख्‍या: 6000",
        descriptionTwo: "संजय गांधी (स.सा.):4000",
      },
    ],
  },
  {
    yojna_id: 4,
    name: "खनिकर्म",
    subyojnas: [
      {
        subyojna_id: 401,
        name: "अवैध गौण खनिज उत्‍खनन व वाहतूकी बाबत प्रकरणांचा तपशील",
        descriptionOne:
          "अवैध उत्‍खनन/ वाहतुक प्रकरणे घरकुलांची एकुण संख्‍या गटविकास अधिकारी यांचेकडुन प्राप्‍त यादी नुसार: 100",
        descriptionTwo:
          "घरकुलासाठी आवश्‍यक वाळुची मागणी वाळु ब्रास मध्‍ये: 600000",
      },
      {
        subyojna_id: 402,
        name: "घरकुलासाठी उपलब्‍ध करुन देण्‍यात आलेल्‍या रेती बाबतची माहिती",
        descriptionOne:
          "शासकिय कामासाठी वाळु मागणी करण्‍यात आलेल्‍या यंत्रणेचे नाव: 100",
        descriptionTwo:
          "शासकिय कामासाठी मागणी करण्‍यात आलेली वाळू ब्रास मध्‍ये: 600000",
      },
      {
        subyojna_id: 403,
        name: "शासकिय कामासाठी उपलब्‍ध करुन देण्‍यात आलेल्‍या वाळु बाबतची माहिती",
        descriptionOne: "अवैध उत्‍खनन/ वाहतुक प्रकरणे: 100",
        descriptionTwo: "आकारण्‍यात आलेली दंडात्‍मक रक्‍कम (रु.लाखात): 600000",
      },
    ],
  },
  {
    yojna_id: 5,
    name: "माझी लाडकी बहीण",
    subyojnas: [
      {
        subyojna_id: 501,
        name: "माझी लाडकी बहीण योजना जिल्हा जालना",
        descriptionOne: "एकूण नाकारले: 100",
        descriptionTwo: "एकूण मंजूर: 600000",
      },
    ],
  },
];

// Styled components
const CustomTabs = styled(Tabs)(({ theme }) => ({
  borderBottom: `2px solid ${theme.palette.divider}`,
  marginBottom: theme.spacing(2),
}));

const CustomTab = styled(Tab)(({ theme }) => ({
  textTransform: "none",
  fontWeight: theme.typography.fontWeightBold,
  margin: theme.spacing(0.5),
  "&.Mui-selected": {
    color: theme.palette.primary.main,
  },
  "&:hover": {
    backgroundColor: theme.palette.action.hover,
  },
}));

const SubyojnaBox = styled(Box)(({ theme }) => ({
  cursor: "pointer",
  border: `1px solid ${theme.palette.divider}`,
  padding: theme.spacing(1),
  borderRadius: theme.shape.borderRadius,
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between", // Ensures space between text and icon
  gap: theme.spacing(1),
  width: "calc(33.33% - 10px)",
  height: "100px", // Set a fixed height
  marginBottom: theme.spacing(2),
  position: "relative",
  "&:hover .hover-content": {
    opacity: 1,
    transform: "translateY(0)",
  },
}));

const SubyojnaIcon = styled(ArrowRightAlt)(({ theme }) => ({
  color: theme.palette.primary.main,
}));

const HoverContent = styled(Box)(({ theme }) => ({
  position: "absolute",
  // bottom: "5px",
  // right: "5px",
  background:
    "linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(0,147,255,1) 0%, rgba(0,212,255,1) 100%)",
  color: "#fff", // Change text color
  padding: theme.spacing(1.5), // Increased padding for more space
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[4], // Stronger shadow for emphasis
  opacity: 0,
  transform: "translateY(10px) scale(0.8)", // Scale down initially
  transition: "all 0.3s ease-in-out",
  zIndex: 10, // Ensure it's above other elements
  fontSize: "20px", // Adjust font size
  width: "100%", // Set fixed width
  height: "100px", // Set fixed height
  display: "flex", // Use flexbox to center content
  alignItems: "center", // Center vertically
  justifyContent: "center", // Center horizontally
  "&:hover": {
    opacity: 1, // Fully visible on hover
    transform: "translateY(0) scale(1)", // Scale to normal size
  },
}));

const StaticTabsComponentVibhag = () => {
  const [value, setValue] = useState(0);
  const navigate = useNavigate();
  const handleItemClick = (subyojna) => {
    navigate(`/tablevibhag/${subyojna}`);
  };

  return (
    <Box
      sx={{
        background: "#fff",
        width: { sm: "90%", xs: "82%" },
        marginLeft: { sm: "6.5%", xs: "12%" },
        marginTop: { sm: "3.5%", xs: "12%" },
        padding: "2rem 0rem",
      }}
    >
      <TopBar />
      <Typography
        variant="h3"
        className="ff_yatra"
        textAlign={"center"}
        fontSize="2.5rem"
        marginBottom="20px"
      >
        विभागीय आयुक्त कार्यालय छत्रपती संभाजीनगर
      </Typography>

      <CustomTabs
        value={value}
        onChange={(event, newValue) => setValue(newValue)}
      >
        {staticYojanas.map((yojna, index) => (
          <CustomTab
            key={yojna.yojna_id}
            label={
              <Box display="flex" alignItems="center">
                <FaStar
                  color={value === index ? "blue" : "black"}
                  size={20}
                  style={{ marginRight: "8px" }}
                />
                {yojna.name}
              </Box>
            }
          />
        ))}
      </CustomTabs>

      <Box sx={{ display: "flex", flexWrap: "wrap", gap: "10px" }}>
        {staticYojanas[value].subyojnas.map((subyojna) => (
          <SubyojnaBox
            key={subyojna.subyojna_id}
            onClick={() => handleItemClick(subyojna.name)}
          >
            <Typography variant="body1" sx={{ flexGrow: 1, textAlign: "left" }}>
              {subyojna.name}
            </Typography>
            <SubyojnaIcon />
            <HoverContent
              className="hover-content"
              onClick={() => handleItemClick(subyojna.name)}
            >
              <Box display="flex" flexDirection="column">
                <Typography variant="caption" sx={{ fontSize: "15px" }}>
                  {subyojna.descriptionOne}
                </Typography>
                <Typography variant="caption" sx={{ fontSize: "15px" }}>
                  {subyojna.descriptionTwo}
                </Typography>{" "}
                {/* Unique description */}
              </Box>
            </HoverContent>
          </SubyojnaBox>
        ))}
      </Box>
    </Box>
  );
};

export default StaticTabsComponentVibhag;
